export enum ContentType {
    Course,
    Document,
    Folder,
    LearningPath,
    Process,
    Video,
    Workflow,
    Task,
    Milestone,
    Step,
    LiveEvent,
    Quiz,
    ScormPackage,
    LiveEventSession,
    Tool,
    Library,
    Template,
    KsAssessment
}
