import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {BehaviorSubject, Observable, of, pipe} from "rxjs";
import {Apiv2Service} from '../apiService/apiv2.service';
import {ProdGenApi} from '../apiService/prodgen.api';
import { environment } from 'src/environments/environment';
import { AIChatRequest, AICompleteChatResponse, ChatContextMessage, ChatMessage } from '../aichat/models/aichat';

@Injectable({
  providedIn: 'root'
})
export class AIChatService {


  constructor(private http: HttpClient,
              private v1Service: ProdGenApi,
              private v2Service: Apiv2Service,
              ) {
    }

  fetchChatResponse(prompt: string, contextMessages: ChatContextMessage[]): Observable<AICompleteChatResponse> {
    if (!prompt) {

      return of(new AICompleteChatResponse);
    }

    // todo integrate with backend, make models, move this url somewhere better for future use

    // TODO - should use v2 when endpoint is ready
    const headersV1 = this.v1Service.getAuthHeaders();
      const headersV2 = this.v2Service.getv2AuthHeaders();
      const url = environment.apiUrl.v2 + '/aichat';
    const headers = headersV2.append('userAccessKey', headersV1.get('userAccessKey'));
    const body = new AIChatRequest();

    body.prompt = prompt;
    body.contextMessages = contextMessages;

    return this.http.post<AICompleteChatResponse>(url, body, { headers: headers });
  }
}
