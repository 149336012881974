import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private _peakExperienceEnabled = false;
  public haveSetPeakExperienceEnabled = false;

  constructor() {}

  get peakExperienceEnabled(): boolean {
    return this._peakExperienceEnabled;
  }

  set peakExperienceEnabled(value: boolean) {
    this._peakExperienceEnabled = value;
    this.haveSetPeakExperienceEnabled = true;
  }
}
