import { ContentType } from "./../../apiService/classFiles/v2-groups";

export class AIChatRequest {
  public prompt: string;
  public contextMessages: ChatContextMessage[] = [];
}

export class ChatContextMessage {
  constructor(public content: string, public isUser: boolean) {
  }
}


export class AIChatResult {
  public id: string;
  public content: string;
  public createdAt: Date;
  public citations: AIChatCitation[]=[];
}

export class AIChatCitation {
  public title: string;
  public url: string;
  public contentType: ContentType;
  public referenceName: string;
}

export class AICompleteChatResponse {
  public result: AIChatResult = new AIChatResult();
}

export class ChatMessage {
  public citations: ChatCitation[] = [];
  constructor(public message: string, public isUser: boolean) {
  }
}

export class ChatCitation {
  constructor(public title: string, public url: string, public contentType: ContentType) { }
}


