import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Organization } from '../../apiService/classFiles/class.organizations';
import { Playlist, PlaylistContent, User } from '../../apiService/classFiles/class.users';
import { UserDataGridComponent } from '../userdatagrid/userdatagrid.component';
import { Content, ContentType_T, DescriptionFilter_T } from './../../apiService/classFiles/class.content';
import { Workgroup, WorkgroupSharContentStatusEnum } from './../../apiService/classFiles/class.workgroups';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { TranslationService } from './../../services/TranslationService';

declare var $: any;

@Component({
    selector: 'template-share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.css'],
    providers: [ProdGenApi]
})



export class ShareComponent implements OnInit {
    @Input('contentItem') item: Content;
    @Input('editorWorkgroups') editorWorkgroups: Array<Workgroup>;
    @Input('sourcediv') sourcediv: string;
    @Input('actionID') actionID: string = "00000000-0000-0000-0000-000000000000";
    @Input('organization') parmOrganization: Organization;
    @Input('sourceType') sourceType: string = '';
    @Input() allowShareToWorkgroups: boolean = true;
    @Input() allowShareToAssets: boolean = true;
    @Input() dropdownState: boolean = false;
    
    @Output() dropdownStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('userGrid', { static: false }) userGridChild: UserDataGridComponent;

    statusText: string = "Asset has been shared to the selected workgroup.";
    PLstatusText: string = "Asset has been shared to My Assets.";
    isDropDown: boolean = true;
    isDropRight: boolean = true;
    displayShareNotification: boolean = true;

    playlist: Playlist = new Playlist();
    plcontent: PlaylistContent = new PlaylistContent()
    plcontentID: string = "";
    user: User = new User();

    style: string = "";
    sanitizedstyle: SafeStyle;

    shareModalID = "#shareViaNotification";

    org: Organization = new Organization();
    assetDefaultPrefix = "Pinnacle Series Asset ";
    assetNowPrefix = "ProductivityNOW ";
    prodNow: string[] = ['342AA211-04BB-4F21-A4C0-5A6FB3AA5511', '5B4D8094-590C-4317-A611-8011B244CA65', 'A972914D-3CFB-4F3F-9585-85A120F12BFF', '9A3D36B1-0206-4A95-9073-F43EDFEB54A8']; 

    observer = new MutationObserver(res => {//keeps track of all dom changes to a specific element
            this.callback(res);
        });

    constructor(private pinnacleService: ProdGenApi, private el: ElementRef, private translationService: TranslationService, private sanitize: DomSanitizer) {
        this.statusText = translationService.getTranslationFileData("SHARE.TS_AssetWaiting");

        //this.pinnacleService.getCurrentUser().subscribe(r => {
        //  this.user = r;
        //  this.pinnacleService.GetPlaylist(this.user.userId).subscribe(pl => {
        //    this.playlist = pl;
        //  });
        //});

    }

    ngOnInit() {
        if (window.location.href.indexOf("/search") != -1) {
            this.displayShareNotification = false;
        }
        else {
            this.displayShareNotification = true;

        }

        this.setOrgData();

    }

    ngAfterViewInit() {

        //console.log(document.getElementById('shareGlyph'));
        let node = document.getElementById('shareGlyph' + this.item.contentId);
        if (node) {
            this.observer.observe(node, {
                attributes: true,
                childList: true,
                characterData: true,
                attributeOldValue:true
            });
        }
    }

    callback(mutations: any) {
        if (mutations[0].target.classList.contains("open") == true) {
            this.dropdownState = true
        }
        else {
            this.dropdownState = false;
        }
        this.dropdownStateChange.emit(this.dropdownState);
    }


    showContactInfo(some_id) {
        var elements = document.getElementsByName(some_id);
        for (var i = 0; i < elements.length; i++) {
            // Toggle hide or show
            elements[i].style.display == "block" ? elements[i].style.display = "none" : elements[i].style.display = "block";

        }
    }

    getEmailBody(): string {

        let subjTitlePrefix = this.assetDefaultPrefix;
        if (this.org != null) {
            for (var j = 0; j < this.prodNow.length; j++) {
                if (this.prodNow[j].toString() == this.org.sellingPartnerId) {
                    subjTitlePrefix = this.assetNowPrefix;
                    break;
                }
            }
        }

        let body = "";
        if (this.item != undefined && this.item.name != undefined) {
            body = "mailto:?subject=";

            let itemName = this.item.name.replace(/&/g, "%26").replace(/#/g, "%23").replace(/'/g, "%27").replace(/"/g, "%22");
            let subject = subjTitlePrefix + itemName;
            body += subject + "&body=";

            let link = document.location.protocol + "//" + document.location.host + "/#" + this.getRoutingLinkURL();
            body += itemName + " ";
            body += link;

            body = encodeURI(body);
        }
        return body;
    }

    getShareLinkURL() {

        let url = document.location.protocol + "//" + document.location.host + "/#";

        url += this.getRoutingLinkURL();

        return url;
    }

    getRoutingLinkURL() {
        let url = "";

        if (this.item.contentType == ContentType_T.cheatsheet) {
            url += "/cheatsheetviewer?id=" + this.item.contentId;
        }
        else if (this.item.contentType == ContentType_T.video) {
            //window.location.href = url += "/videoviewer?id=" + url_array[1];
            url += "/videoviewer?id=" + this.item.contentId;
        }
        else if (this.item.contentType == ContentType_T.learningpath) {
            url += "/learningcenter/series?learningPathId=" + this.item.contentId;
        }
        else if (this.item.contentType == ContentType_T.course) {
            url += "/learningcenter/series?courseId=" + this.item.contentId;
        }
        else if (this.item.contentType == ContentType_T.trainingclass) {
            url += `/liveeventviewer?sessionId=${this.item.contentId}`;
        }
        else if (this.item.contentType == ContentType_T.workflow || this.item.contentType == ContentType_T.process || this.item.contentType == ContentType_T.task || this.item.contentType == ContentType_T.step) {
            let contentType = "";
            if (this.item.contentType == ContentType_T.workflow) {
                contentType = "workflow";
            }
            else if (this.item.contentType == ContentType_T.process) {
                contentType = "process";
            }
            else if (this.item.contentType == ContentType_T.task) {
                contentType = "task";
            }
            else if (this.item.contentType == ContentType_T.step) {
                contentType = "step";
            }
            url += "/workflowviewer?id=" + this.item.contentId + "&contentType=" + contentType;
        }
        return url;
    }


    copyLinkToClipboardFormatted() {
        /*
        let text = `<a href="` + window.location.href + `"><u>` + this.item.name + `</u></a>`;
        this.copyToClipboard(text);
        */

        let url = this.getShareLinkURL();
        let text = `<a href="` + url + `"><u>` + this.item.name + `</u></a>`;

        this.copyToClipboard(text);

    }

    copyLinkToClipboardUnformatted() {
        //this.copyToClipboard(window.location.href);

        let url = this.getShareLinkURL();
        this.copyToClipboard(url);

    }

    addToWorkgroup(w: Workgroup) {

        console.log("A");

        //$('#addToWorkgroupModal').appendTo("body").modal('show');
        $('#addToWorkgroupModal').modal('show');

        this.statusText = this.translationService.getTranslationFileData("SHARE.TS_AssetChecking");

        this.pinnacleService.SetWorkgoupContentItem(w.workgroupId, this.item.contentId, this.item.contentType).subscribe(res => {
            console.log(res);
            if (res.Status == WorkgroupSharContentStatusEnum.AlreadyExist) {
                this.statusText = this.translationService.getTranslationFileData("SHARE.TS_AlreadySharedAssetWorkgroup") + " '" + w.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_PostAssetSharedWorkgroup");
            }
            else if (res.Status == WorkgroupSharContentStatusEnum.Success) {
                this.statusText = "'" + this.item.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_MidAssetSharedWorkgroup") + " '" + w.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_PostAssetSharedWorkgroup");
            
            }
            else {
                this.statusText = this.translationService.getTranslationFileData("SHARE.TS_FailedAssetSharedWorkgroup");

            }
        }, err => {

        });

        
        /*
        this.pinnacleService.GetWorkgroupContentItems(w.workgroupId, -1, 0, DescriptionFilter_T.unformatted).subscribe(res => {
            let assetFound = false;

            for (let c of res) {
                if (c.contentId == this.item.contentId) {
                    assetFound = true;
                    break;
                }
            }

            if (assetFound) {
                this.statusText = this.translationService.getTranslationFileData("SHARE.TS_AlreadySharedAssetWorkgroup") +" '" + w.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_PostAssetSharedWorkgroup");

            }
            else {
                this.statusText = this.translationService.getTranslationFileData("SHARE.TS_AssetWaiting");
                this.pinnacleService.SetWorkgoupContentItem(w.workgroupId, this.item.contentId, this.item.contentType).subscribe(res => {
                    this.statusText = "'" + this.item.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_MidAssetSharedWorkgroup") + " '" + w.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_PostAssetSharedWorkgroup");});
            }

        });
        */

        ///this.statusText = this.translationService.getTranslationFileData("SHARE.TS_AssetWaiting");
        //this.pinnacleService.SetWorkgoupContentItem(w.workgroupId, this.item.contentId, this.item.contentType).subscribe(res => {
        //    this.statusText = "'" + this.item.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_MidAssetSharedWorkgroup") + " '" + w.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_PostAssetSharedWorkgroup");;

            // setup the notification for members
            // get the member list
            //this.pinnacleService.GetWorkgroupMembers(w.workgroupId).subscribe(res => {
            //    let userIds: Array < string > = new Array<string>();
            //    for (let member of res)
            //    {
            //        userIds.push(member.userId as string);
            //    }
            //    let title = w.name + " has changed";
            //    let text = ProdGenApi.getSessionUser().name + " has added '" + this.item.name + "' to the '" + w.name + "' work group."
            //    let linkUrl = "/workcenter?id=" + w.workgroupId;
            //    this.pinnacleService.SetNotification(title, text, ProdGenApi.getSessionUser().imageURL as string, linkUrl, userIds).subscribe();
            //});

        //});
    }


    copyToClipboard(textToCopy: string): boolean {

        // create hidden text element, if it doesn't already exist
        //var targetId = "_hiddenCopyText_";
        //var origSelectionStart, origSelectionEnd;
        var aux = document.createElement("div");
        aux.setAttribute("contentEditable", "true");
        aux.innerHTML = textToCopy;
        aux.setAttribute("style", "background-color:white;");


        //aux.setAttribute("onfocus", "document.execCommand('selectAll',false,null)");
        document.getElementById(this.sourcediv).appendChild(aux);
        window.getSelection().selectAllChildren(aux);
        aux.focus();
        
        document.execCommand("copy");
        document.getElementById(this.sourcediv).removeChild(aux);
        return true;

        // must use a temporary form element for the selection and copy
        //var target = document.createElement("textarea");
        //target.style.position = "absolute";
        //target.style.left = "-9999px";
        //target.style.top = "0";
        //target.id = targetId;
        //document.body.appendChild(target);
        //target.textContent = textToCopy;

        //// select the content
        //var currentFocus = document.activeElement;
        //target.focus();
        //target.setSelectionRange(0, target.value.length);

        //// copy the selection
        //var succeed;
        //try {
        //    succeed = document.execCommand("copy");
        //} catch (e) {
        //    succeed = false;
        //}

        //try
        //{
        //    document.body.removeChild(target);
        //}
        //catch(e){}
        //// clear temporary content
        //target.textContent = "";
        //return succeed;
    }


    checkDropDownStatus() {
        if (this.el.nativeElement.offsetTop + this.el.nativeElement.offsetHeight + 350 > document.body.scrollHeight - 130 - 58) {
            this.isDropDown = false;
        }
        else {
            this.isDropDown = true;
        }
        if (document.getElementById('productListingPage')) {//having this element on the page means we are on the asset library and need to do calculations
            if ((this.el.nativeElement.offsetTop + this.el.nativeElement.offsetHeight + 350) > document.getElementById(this.sourcediv).scrollHeight + 127 /*height of asset type header*/) {
                this.isDropDown = false;
            }
            else {
                this.isDropDown = true;
            }
        }
        else if (document.getElementById('courseSyllabusinternal')) {

            if (this.el.nativeElement.offsetLeft + this.el.nativeElement.offsetWidth + 250 > document.getElementById('courseSyllabusinternal').offsetWidth) {
                this.isDropRight = false;
            }
            else {
                this.isDropRight = true;
            }
        }
        else if (this.sourceType == 'liveEventRegistrationform') {
            this.isDropRight = false;
        }
        else if (this.sourceType == 'syllabusTemplate') {
            this.isDropDown = true;
        }
        else {
            this.isDropRight = true;
        }

        this.shareModalID = "#shareViaNotification" + this.item.contentId;

        // Used to adjust spacing in courseviewersyllabus
        this.dropdownState = !this.dropdownState;
        this.dropdownStateChange.emit(this.dropdownState);
    }

    positionDropdown() {
        if (this.isDropRight == false) {
            this.style = "position:absolute;z-index:15001 !important;width:250px;left:-220px;";
            this.sanitizedstyle = this.sanitize.bypassSecurityTrustStyle(this.style);
            return this.sanitizedstyle;

        }
        else {
            this.style = "z-index:15001 !important;width:250px;";
            this.sanitizedstyle = this.sanitize.bypassSecurityTrustStyle(this.style);
            return this.sanitizedstyle;

        }
    }

    getDropDownClass() {

        if (this.isDropDown) {
            return "action-glyph dropdown";
        }
        else {
            return "action-glyph dropup";
        }
    }

    AddtoPlaylist() {
        $('#addToPlaylistModal').appendTo("body").modal('show');
        this.plcontent.addeddatetime = new Date();
        this.plcontent.bisdownloadable = true;
        this.plcontent.contentId = this.item.contentId.toString();
        this.plcontent.contentType = this.item.contentType;
        this.plcontent.plid = this.playlist.plid;
        this.plcontent.tenantid = this.playlist.tenantid;

        this.pinnacleService.getCurrentUser().subscribe(r => {
            this.user = r;
            this.pinnacleService.GetPlaylist(this.user.userId).subscribe(pl => {
                this.playlist = pl;



                if (this.plcontent.contentType == ContentType_T.course) {
                    this.pinnacleService.GetEnrollmentIDFromCourse(this.plcontent.contentId).subscribe(r => {
                        this.pinnacleService.AddtoPlaylist(this.playlist.plid, this.item.contentId.toString(), this.plcontent, r).subscribe(res => {
                            this.plcontentID = res;
                            //this.PLstatusText = "'" + this.item.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_MidAssetSharedPlaylist") + " '" + this.playlist.name + "' ";;


                            this.pinnacleService.GetPlaylist(this.user.userId).subscribe(pl => {
                                this.playlist = pl;

                            });

                        });
                    });
                }
                else {
                    this.pinnacleService.AddtoPlaylist(this.playlist.plid, this.item.contentId.toString(), this.plcontent).subscribe(res => {
                        this.plcontentID = res;


                        this.pinnacleService.GetPlaylist(this.user.userId).subscribe(pl => {
                            this.playlist = pl;

                        });

                    });
                }
            });
        });
    }

    tenantUsers = [];
    tenantGroups = [];
    selectedUsers = [];
    selectedGroups = [];
    dropdownSettingsUsers = {};
    dropdownSettingsGroups = {};
    usersLoaded = false;
    groupsLoaded = false;
    titleText = "";
    titleDefault = "";
    bodyText = "";
    bodyDefault = "";
    userImage = "";
    showusergrid: boolean = false;

    shareNotification() {
        $('#shareViaNotification'+ this.item.contentId).appendTo("body").modal('show');
        this.selectedUsers = [];
        this.selectedGroups = [];
        this.dropdownSettingsUsers = {};
        this.dropdownSettingsGroups = {};

        

        $('#shareViaNotification' + this.item.contentId).on('shown.bs.modal', ()=> {
            setTimeout(() => { this.showusergrid = true; }, 1);
        })

        this.bodyDefault = this.translationService.getTranslationFileData("SHARE.TS_BodyDefault") + ": " + this.item.name;
        this.bodyText = this.bodyDefault;

        (document.getElementById("titleInput") as HTMLInputElement).value = "";
        (document.getElementById("bodyInput") as HTMLInputElement).value = "";

        this.dropdownSettingsUsers = {
            singleSelection: false,
            enableCheckAll: true,
            enableFilterSelectAll: true,
            text: this.translationService.getTranslationFileData("SHARE.TS_SelectUsers"),
            enableSearchFilter: true,
            classes: "multi-select-dropdown",
            selectAllText: this.translationService.getTranslationFileData("SHARE.TS_SelectAll"),
            unSelectAllText: this.translationService.getTranslationFileData("SHARE.TS_UnSelectAll"),
            searchPlaceholderText: this.translationService.getTranslationFileData("SHARE.TS_Search")
        };
        this.dropdownSettingsGroups = {
            singleSelection: false,
            enableCheckAll: true,
            enableFilterSelectAll: true,
            text: this.translationService.getTranslationFileData("SHARE.TS_SelectGroups"),
            enableSearchFilter: true,
            classes: "multi-select-dropdown",
            selectAllText: this.translationService.getTranslationFileData("SHARE.TS_SelectAll"),
            unSelectAllText: this.translationService.getTranslationFileData("SHARE.TS_UnSelectAll"),
            searchPlaceholderText: this.translationService.getTranslationFileData("SHARE.TS_Search")
        };

        this.pinnacleService.getCurrentUser().subscribe(u => {
            this.user = u;
            if (u.imageURL == null || u.imageURL == "") {
                this.userImage = "../../../assets/images/default_user.jpg";
            }
            else {
                this.userImage = u.imageURL;
            }

            this.titleDefault = this.user.name + " " + this.translationService.getTranslationFileData("SHARE.TS_TitleDefault");
            this.titleText = this.titleDefault;
        });

        this.pinnacleService.getUsers().subscribe(res => {
            this.usersLoaded = false;
            this.tenantUsers = [];
            for (let i = 0; i < res.length; i++) {
                this.tenantUsers.push({ "id": res[i].userId, "itemName": res[i].name });
            }
            this.usersLoaded = true;
        });

        this.pinnacleService.getJobRoles().subscribe(res => {
            this.groupsLoaded = false;
            this.tenantGroups = [];
            for (let i = 0; i < res.length; i++) {
                this.tenantGroups.push({ "id": res[i].roleId, "itemName": res[i].roleName });
            }
            this.groupsLoaded = true;
        });
        this.actionID = this.item.contentId as string;
    }

    sendNotifications() {
        this.selectedUsers = this.userGridChild.getSelectedUserIds();
        this.userGridChild.reset();
        let url = this.getRoutingLinkURL();
        
        if (this.selectedUsers.length > 0) {

            this.sendNotifications2(this.selectedUsers, this.titleText, this.bodyText, this.user.imageURL, url, false);
        }
        else {
            if (this.selectedGroups.length > 0) {

                this.sendNotifications2(this.selectedGroups, this.titleText, this.bodyText, this.user.imageURL, url, true);
            }
        }
    }

    private sendNotifications2(p_selection,
                                p_title: string,
                                p_text: string,
                                p_senderImg: string,
                                p_url: string,
                                p_isGroup: boolean) {

        let v_IDs = new Array<string>();


        for (let i of p_selection) {
            if (i.id) {
                v_IDs.push(i.id);
            }
            else {
                v_IDs.push(i);
            }

        }



        this.pinnacleService.SetNotification(
            p_title,
            p_text,
            p_senderImg,
            p_url,
            v_IDs,
            p_isGroup).subscribe(res => {
                if (!p_isGroup && this.selectedGroups.length > 0) {
                    this.sendNotifications2(this.selectedGroups, this.titleText, this.bodyText, this.user.imageURL, p_url, true);
                }
            });
    }

    updatePreview(event) {
        if (event.target.id == "titleInput") {
            if (event.target.value != "") {
                if (event.target.value.length > 49) {
                    (event.target as HTMLInputElement).style.backgroundColor = "rgba(230,0,0,0.1)";
                }
                else {
                    this.titleText = event.target.value;
                    (event.target as HTMLInputElement).style.backgroundColor = "transparent";
                }         
            }
            else {
                this.titleText = this.titleDefault;
            }
        }
        if (event.target.id == "bodyInput") {
            if (event.target.value != "") {
                if (event.target.value.length > 149) {
                    (event.target as HTMLInputElement).style.backgroundColor = "rgba(230,0,0,0.1)";
                }
                else {
                    this.bodyText = event.target.value;
                    (event.target as HTMLInputElement).style.backgroundColor = "transparent";
                }
            }
            else {
                this.bodyText = this.bodyDefault;
            }
        }
    }

    setOrgData() {
        if (this.parmOrganization && this.parmOrganization != new Organization()) {
            this.org = this.parmOrganization;
        }
        else {
            try {
                this.org = ProdGenApi.getSessionOrganization();
                if (this.org == null) {
                        this.pinnacleService.GetCurrentOrganization().subscribe(resp => {
                            this.org = resp;
                        });
                }
            }
            catch (e) { return false; }
        }
    }

    // Limit the users shown in the grid.
    filterUserIds: Array<string>;
    filterMode: 'exclude' | 'include' = 'exclude';
    setUserFilter(userIds: string[], mode: 'exclude' | 'include' = 'exclude'): void {
        this.filterUserIds = userIds;
        this.filterMode = mode;
    }

}
