export class Organization{
	organizationId: String;
	name: String;
	sellingPartnerId: String;
}

export class Tenant {
	tenantId: String;
	organizationId: number;
    public altOrganizationId: string;
    public name: string;
    public isPinnacleLite: boolean;
    public expiration: Date;
    public maxPinnacleLiteUsers: number;
}

export class PartnerCustomerTenantInfo {
    tenantId: string;
    orgId: number;
    altOrgId: number;
    partnerName: string;
    clientName: string;
    expiration: Date;
    isPinnacleLite: boolean;
    isPeakEnabled: boolean;
}

export class GetPartnerTenantsResponse {
    partnerTenants: PartnerCustomerTenantInfo[];
}

export class TenantWithLogo {
    tenantId: String;
    organizationId: number;
    public altOrganizationId: string;
    public name: string;
    public isPinnacleLite: boolean;
    public expiration: Date;
    public maxPinnacleLiteUsers: number;
    logo: string;
}


export class Setting {
    public settingId: String;
    public tenantId: String;
    public userId: String;
    public name: String;
    public settingType: String;
    public settingValue: Object;
}
export class CompanyNewsItem {
    public newsId: String;
    public tenantId: String;
    public name: String;
    public description: String;
    public linkUrl: String;
    public seq: number;
    public updateMsg: String;
}

export class PinnacleLiteSettings {
    public isPinnacleLite: boolean;
    public createdAsPinnacleLite: boolean;
    public upgradeDate: Date;
}

export class ThemeItem {
    public itemName: string;
    public itemValue: string;
}

export class Theme {
    public themeID: string;
    public themeName: string;
    public themeproperties: Array<ThemeItem>;
}

