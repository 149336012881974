import { ContentType_T } from "./class.content";

export class UserExpertise {
    public expertiseId: string;
    public expertiseName: string;
}

export class GetExpertiseResponse {
    public expertise: UserExpertise[];
} 


export class ModifyUserExpertiseRequest {
    public expertiseIdsToRemove: string[];
    public expertiseIdsToAdd: string[];
}

export class ModifyUserExpertiseResponse {
    public expertiseAdded: string[];
    public expertiseRemoved: string[];
}

export class GetKnowledgeSmartResponse {
    public knowledgeSmartConfiguration: KnowledgeSmartConfiguration;
}

export class KnowledgeSmartConfiguration {
    public knowledgeSmartAPI_Key: string = "";
    public useKnowledgeSmartIntegration: boolean = false;        
    public assessmentSelfEnroll: boolean = false;
}



export class KS_UI_Options {
    public id: number;
    public displaySkipQuestionButton: boolean; 
    public displayRequestTrainingButton: boolean;
    public displayUserDataCapturePage1Start: boolean;
    public displayUserDataCapturePage2End: boolean;
    public hideTestLogoutLink: boolean;
    public enableUserPageAccess: boolean;
    public showDigitalProctoringMessage: boolean;
}

export class GetAllAssessmentsResponse {
    public KSAssessments: Array<KSAssessment>;
}

export class KSAssessment {
    public testID: number;
    public name: string;
    public fullName: string;
    public description: string;
    public timeLimitMins: string;
    public offTheShelf: boolean;
    public published: boolean;
}

export class GetAssignmentResultsForUserResponse {
    public ksResults: Array<KSResult>;
}

export class KSResult {
    public userId: string;
    public forename: string;
    public surname: string;
    public userStatus: string;
    public loweredEmail: string;
    public score: number;
    public testDate: Date;
    public lastKnownTime: Date;
    public totalElapsedTimeMins: number;
    public testName: string;
    public resultID: number;
    public timeMins: number;
    public testId: number;
    public assessmentID: number;
    public isExam: boolean;
    public showScore: boolean;

}

export class GetAssignmentsNotStartedForUserResponse {
    public ksAssignments: Array<KSAssignment>;
}
export class GetAssignmentsInProgressForUserResponse {
    public ksAssignments: Array<KSAssignment>;
}

export class KSAssignment {
    public assessmentID: number;
    public testID: number;
    public sentDate: Date;
    public expiryDate: Date;
    public testName: string;
    public status: string;
    public userId: string;
    public forename: string;
    public surname: string;
    public email: string;
}

export class GetContentFromAssessmentResponse {
    public actionIds: Array<string>;
    public assessmentAction: AssessmentActionType;
    public templateMappings: Array<KSMappedAssessment>;
}

export class KSMappedAssessment {
    public ksAssessmentId: number;
    public ksAssessmentName: string;
    public templateId: string;
    public templatePathAndName: string;
    public templateType: ContentType_T;
    public templateSubId: string;
    public templatePath: string;

}

export class GetAssessmentsFromContentResponse {
    public templateMappings: Array<KSMappedAssessment>;
}

export enum AssessmentActionType {
    Enroll,
    Navigate,
    None
}


export class KSUser {
    public userInfoForAccountID: number;
    public accountID: number;
    public  applicationId: string;
    public  userID: string;
    public  userName: string;
    public  loweredUserName: string;
    public  mobileAlias: string;
    public isAnonymous: boolean;
    public  email: string;
    public  forename: string;
    public  surname: string;
    public  status: string;
    public companyName: string;
}

export class AssignAssessmentResponse {
    public assessmentId: number;
}
export class AssignAssessmentRequest {
    public userEmail: string;
    public testID: number;
    public displayName: string;
    public status: string;
    public hasExpiryDate:boolean
    public expiryDate: Date;
}

export class TermsAndConditionsAcceptedKSResponse {
    public policyId: string;
    public acceptedTermsAndConditions: boolean;

    public termsAndConditionsType: string;
    public acceptedDate: string
}

export class KSInviteToAssessment {
    public ksInviteId: number;
    public psUserId: string;
}